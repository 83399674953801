
.header {
    background-color: var(--backgroundColor);
    height: var(--headerHeight);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between; 
    
}

.nav a{
    margin: 1rem;
    cursor: pointer;
}

