.r-page {
    width:100%;
    height: 100%;

    background-color: var( --almost-white);
    display: grid;
    grid-template-columns: 150px 1fr 1fr;
    gap: 10px;
    /* background-repeat: repeat;
    background-image: url("6_pies.png"); */
}


.r-form {
    background-color: var(--secondaryColor);
    width: 360px;
    height: 360px;
    margin: 0 auto auto auto;
    padding: 20px;
  }

.r-form input {
    padding: 5px 0;
    font-size: 1.1rem;
}

.r-period {
    display: grid;
    grid-template-columns: 30% 1fr;
    gap: 10px;
    /* align-items: center;
    justify-content: flex-start; */
}

.r-period label {
    margin: auto 0 auto auto;
}

.r-form button {
    width: 100%;
    height: 60px;
    margin-top: 10px;
}

.r-days-of-week {
    display: grid;
    grid-template-columns: 20% 1fr;
    gap: 5px;

}

.r-days-of-week label {
    text-align: left;
}

.r-days-of-week input {
    margin: auto 0 auto auto;
}

/* PREVIOUS DAYS */
.pd-panel {
    background-color: var( --primaryColor);
    width: 400px;
    height: 100%;
    margin: auto;
}
.pd-panel h2 {
    margin: 30px auto;
}

.pd-panel form {
    width: 360px;
    margin: 0 auto auto auto;
    
}

.pd-panel form fieldset {
    display: flex;
    justify-content: center;
}

.pd-panel form legend {
    color: var(--middle-grey)
}

.pd-panel form input {
    padding: 5px 5px;
    font-size: 1.1rem;
    margin: 15px;
}

/* .pd-panel form label {
    color: var(--lightGrey);
} */
