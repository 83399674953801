.today-page {

    display: grid;
    grid-template-columns: auto 1fr;  
    height: 100%;

}
.today {
    background-color: var(--primaryColor);
    position: relative
}

.s-bottom-section {
    position: absolute;
    bottom: 0;

}



.empty-logo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 0.5px solid var(--secondaryColor);
}
.empty-logo img {
    width: 200px;
    height: 200px;
}
.empty-logo h1{
    padding: 0;
    margin: 0;
}