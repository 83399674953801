:root {
  --white: #FFFFFF;
  --almost-white: #FAFAFA;
  --lightGrey: #EEEEEE;
  --lightGreyGrey: #DDDDDD;
  --grey: #CCCCCC;
  --middle-grey: #BBBBBB;
  --darkGrey: #999999;
  --dim-grey: #555555;
  --black: #000000;
  --red: #FF0000;
  --blue: #0505c6;
  
  --ochreLight: #FFF6E9; 
  --primaryColor: #009900;
  --secondaryColor: #CCCCCC;
  --backgroundColor: #FAFAFA;
  --lightGreen: #87C17C;
  --light-light-green: #b5eeaa;

  --headerHeight: 50px;
  --button-width: 100px;
  --thumbnail-width: 110px;
  --small-button-width: 80px;
  --upperPanelHeight: 100px;
  --report-landscape-width: 1200px;
}

body {
  max-width: 1200px;
  margin: auto;
  font-family: 'Roboto', sans-serif; 
  print-color-adjust: exact;
}

button, input, div, p, h1, h2, h3, h4, h5, h6 {
  font-family: 'Roboto', sans-serif; 
  color: var(--black);
}


/* 100% or empty */
.App {
  height: 100vh; 
  display: grid;
  grid-template-rows: 50px 1fr;
}

.green {
  color: var(--primaryColor);
}
.black {
  color: var(--black);
}
.white {
  color: var(--white);
}
.grey {
  color: var(--secondaryColor); 
}
.dark-grey {
  color: var(--dim-grey); 
}
.red {
  color: var(--red); 
}
.dark-grey {
  color: var(--darkGrey);
}
.blue {
  color: var(--blue);
}
.hand-written {
  color: var(--blue);
  font-style: italic;
}

/* BACKGROUND STYLES */
.bg-ochre {
  background-color: var(--ochreLight);
}
.bg-white {
  background-color: var(--white);
}
.bg-almost-white {
  background-color: var(--almost-white);
}
.bg-light-grey {
  background-color: var(--lightGrey);
}
.bg-middle-grey {
  background-color: var(--middle-grey);
}
.bg-dark-grey {
  background-color: var(--darkGrey);
}
.bg-green {
  background-color: var(--primaryColor);
}
.bg-light-green {
  background-color: var(--lightGreen);
}
.bg-light-light-green {
  background-color: var(--light-light-green);
}



.light {
  font-weight: lighter;
}
.light div {
  font-weight: lighter;
}
.bold {
  font-weight: bold; 
}
.italic {
  font-style: italic;
}
.semi-bold {
  font-weight: 500; 
}
.ta-left {
  text-align: left;
}

.crossed {
  text-decoration: line-through;
  text-decoration-thickness: 2px;
  text-decoration-color: var(--blue);
  margin: auto 5px auto auto;
}

.small {
  font-size: 0.9rem;
}
.x-small {
  font-size: 0.7rem;
}

.mg-top-10 {
  margin-top: 10px;
}

.square-button {
  width: var(--button-width);
  height: var(--button-width);
  font-size: 1.3rem;
  color: var(--black);
  background-color: var(--secondaryColor);
  border: 0;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.square-button div {
  font-weight: lighter;
}
.square-button-img {
  width: 40px;
  height: auto;
}

.small-square-button {
  width: var(--small-button-width);
  height: var(--small-button-width);
  font-weight: bold;
  font-size: 1.3rem;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.plus {
  font-size: 1.7rem;
}

.active {
  background-color: var(--white);
}

.square-button:active {
  opacity: 0.8;
}

button:active {
  opacity: 0.8;
}

input::placeholder {
  font-style: italic;
  color: var(--darkGrey);
}

textarea::placeholder {
  font-style: italic;
  color: var(--darkGrey);
}

.date {
  font-weight: lighter;
  font-style: italic;
  font-size: 1.7rem;
  padding: 0;
}

h1, h2 {
  padding: 0;
  text-align: center;
  /* background-color: #FFF6E9; */
  /* margin-top: 15px;
  margin-left: 15px; */
}

.green-button {
  background-color: var(--primaryColor);
  color: var(--white); 
  border: 2px solid var(--primaryColor);
  padding: 10px 20px;
}
.green-button div {
  color: var(--white); 
}

.empty-green-button {
  background-color: inherit;
  color: var(--primaryColor); 
  border: 2px solid var(--primaryColor);
  padding: 10px 20px;
}
.empty-green-button div {
  color: var(--primaryColor);
}

.empty-black-button {
  background-color: inherit;
  color: var(--black); 
  border: 2px solid var(--black);
  padding: 10px 20px;  
}
.empty-black-button div {
  color: var(--black);  
}

.empty-white-button {
  background-color: inherit;
  color: var(--white); 
  border: 2px solid var(--white);
  padding: 10px 20px;
}
.empty-white-button div {
  color: var(--white); 
}

.button-img {
  height: 25px;
  width: auto;
  margin-right: 10px;
}

.text-align-middle {
  /* vertical-align: middle; */
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* WARNING */
.warning{
  width: fit-content;
  margin: 20px auto;
}
.warning h4 {
  color: var(--red);
}
.warning p {
  color: var(--red);
}

/* GENERAL STYLES */
.center{
  text-align: center;
}
.mg-auto {
  margin:auto;
}

.three-buttons-in-a-row {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(3, 1fr);
  margin: 10px;
}

.small-icon {
  width: 40px;
  height: 40px;
  cursor: pointer;
}

.hidden{
  display: none;
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}


/* LOGIN PAGE */
.login-page{
  width:100%;
  height: 100%;
  position: relative;
}
.login-page img {
  z-index: 1; 
  width: 100%;
  height: auto;
  position: absolute;
  bottom: 10px;
}

.green-panel {
  z-index: 10; 
  height: 100%;
  width: 450px;
  margin: auto;
  background-color: var(--primaryColor);
  position: relative;
  text-align: center;
}
.green-panel h2 {
  padding-top: 50px;
}
.green-panel form {
  width: 80%;
  margin: 70px auto auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.green-panel input, button {
  width: 100%;
  margin: 7px auto;
  padding: 10px 0;
  font-family: 'Segoe UI';
  font-size: 1.2rem;
}

.green-panel button {
  border: 0;
}

/* LAYOUTS */
.grid-1 {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
}

.grid-2 {
  width: 100%;
  display: grid;
  grid-template-columns:  1fr 1fr;
  align-items: center;
  justify-content: center;
}

.gap-10 {
  gap: 10px;
}
/* .grid-2 div, h2 {
  text-align: center;
} */

.grid-3 {
  width: 100%;
  display: grid;
  grid-template-columns:  1fr 1fr 1fr;
  align-items: center;
  justify-content: center;
}

/* .grid-3 div, h2, h3 {
  text-align: center;
} */

/* MESSAGE SCREEN */
.display-message {
  position: absolute;
  top:0;
  bottom:0;
  left: 0;
  right: 0;
  z-index: 12;
}

.message-screen {
  height: 100%;
  width:  100%;
  background-color: var(--black);
  opacity: 35%;
}

.message-screen-form {
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  width:500px; 
  height:300px; 
  margin:auto;
  background-color: var(--almost-white);
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .message-screen-form h2 {
  margin-bottom: 10px;
} */
.message-screen-form p {
  margin-bottom: 40px;
}

/* SMALL CANCEL ICON img*/

.cancel-icon {
  height: 25px;
  width: 25px;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}
.cancel-icon:hover {
  background: var(--secondaryColor);
}
.cancel-icon:active {
  background: var(--secondaryColor);
}

/* PRINTING */
/* PAGE BREAK for printing */
.page-break {
  page-break-after : always
}

.portrait {
  @page: {size: portrait}
}

.landscape {
  @page: {size: landscape}
}

@media print {
  .noprint {
     visibility: hidden;
     height: 0;
  }

  /* no service info at the top and bottom of the page */
@page {
    /* marks: crop; */
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  
} 
