/* STYLES FOR SIGNATURE BOX */
.sb-signature-box {
    position: absolute;
    top:0;
    bottom:0;
    left: 0;
    right: 0;
    z-index: 12;
}

.sb-background {
    height: 100%;
    width:  100%;
    background-color: var(--black);
    opacity: 35%;
}

.sb-container {
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    width: fit-content;
    height: fit-content;

    margin: auto;
    background-color: var(--lightGrey);
    padding: 20px;

    display: flex;
    flex-direction: column;
    align-items: center;

}
.sb-container canvas {
    stroke: var(--blue);
    touch-action: none;
}

.sb-container h2 {
    font-weight: lighter;
    font-style: italic;
    width: 100%;
    text-align: left;
}

.sb-container canvas {
    background-color: var(--white);
    cursor: crosshair;
}

.sb-container div {
    width: 100%;
}

