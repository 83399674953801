.temp-check {
    width: 1000px;
    margin: 0 auto;
}


.r-report p, .r-report h1, .r-report h2, .r-report h3, .r-report h4, .r-report h5 {
    margin-top: 0;
    margin-bottom: 0;
    padding: 3px 5px;
}

.tc-header {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 50px;
}

.tc-header h3 {
    font-size: 1.4rem;
    color: var(--primaryColor)
}
/* ICO  */
.tc-header img {
    height: 50px;
    width: auto;
}

.join-2 {
    grid-column: 1 / 3;
}

.tc-table {
    width: 100%;
}

.tc-100 {
    width: 100%;
}

.tc-number{
    text-align: center;

}

.tc-table-section {
    border: 2px solid;
    border-top: 0;
}
.tc-bdr-right {
    border-right: 2px solid;    
}
.tc-bdr-left {
    border-left: 2px solid;    
}
.tc-bdr-top {
    border-top: 2px solid;    
}
.tc-bdr-bottom {
    border-bottom: 2px solid;    
} 

.tc-thin-bdr-right {
    border-right: 0.5px solid;    
}
.tc-thin-bdr-left {
    border-left: 0.5px solid;    
}
.tc-thin-bdr-top {
    border-top: 0.5px solid;    
}
.tc-thin-bdr-bottom {
    border-bottom: 0.5px solid;    
}

.tc-empty-line {
    height: 1.2rem;
    margin: 0;
}
/* TABLE HEADER */
.tc-table-header, .baking-program, .tc-product {
    display: grid;
    grid-template-columns: 1fr 28%;   
}

.tc-table-header h3 {
   text-align: center;
   padding-top: 6px;
   padding-bottom: 6px;
   font-size: 1.3rem;
}


.tc-three {
    display: grid;
    grid-template-columns: 30% 1fr 1fr;
}
.tc-three p {
    margin: auto 0 auto auto;
}

.tc-tree-pr {
    display: grid;
    grid-template-columns: 1fr 12% 12%;   
}
.tc-tree-pr p {
    font-size: smaller;
    padding: 4px 5px;
}

.tc-two-cols {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    justify-content:center;
}


.tc-shapes {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}
.tc-shapes img {
    height: 25px;
}

/* BAKING PROGRAM */
.baking-program, .footer {
    background-color: var(--lightGreyGrey);
}

.tc-comment {
    display: flex;
    flex-direction: row;
}
.tc-comment p {
    padding: 1rem;
}

/* SIGNATURE */
.tc-small-signature {
    height: 16px;
    width: auto;

}
.tc-large-signature {
    height: 30px;
    width: auto;
    margin: auto;
}

/* WASTAGE REPORT */

.wr-wastage-report {
    width: var(--report-landscape-width);
    margin: 0 auto;
    background-color: var(--white);
    text-align: center;
}

.wr-header{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.wr-table {
    width: 100%;
    text-align: center;
}
.wr-table p {
    margin: 0;
    font-size: 0.9rem;
}
.wr-h4{
    padding-top: 5px;
    padding-bottom: 5px;
}
/* Divisions and grids */
.wr-div-6 {
    width: 100%;
    display: grid;
    grid-template-columns: 3.5% 26.5% 1fr 1fr 1fr 1fr;
} 
.wr-div-3 {
    width: 100%;
    display: grid;
    grid-template-columns:1fr 1fr 1fr;    
}
/* PRINT AND BACK BUTTONS */
.report-button-container {
    display: flex;
    flex-direction: row;
    justify-content: left; 
}
.print-button-container button {
    width: 300px;
    margin-right: 20px;
}

/* SALES REPORT */
.sr-sales-report {
    width: var(--report-landscape-width);
    margin: 0 auto;
    background-color: var(--white);
    text-align: center;
}
.sr-div-4{
    width: 100%;
    display: grid;
    grid-template-columns: 5% 30% 30% 1fr;    
}
.sr-div-5{
    width: 100%;
    display: grid;
    grid-template-columns: 4% 25% 22% 22% 1fr;    
}

.sr-equal-div-4{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);    
}

.sr-equal-div-5{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);    
}

/* RECOMMENDATIONS */
.rr-div-5 {
    display: grid;
    grid-template-columns: 3.5% 25% 1fr 1fr 1fr;
} 

.rr-100 {
    width: 100%
}
