.category {
    text-align: center;
    color: var(--primaryColor);
    font-weight: normal;
    font-style: italic;
    text-decoration: underline;
    font-size: 1.6rem;
    margin-top: 10px;
    margin-bottom: 5px;
}

.category:first-child {
    margin-top: 0;  
}

.category-2 {
    width: 100%;
    text-align: center;
    color: var(--white);
    background-color: var(--primaryColor);
    font-weight: normal;
    font-size: 1.5rem;
    font-style: italic;
    padding-top: 5px;
    padding-bottom: 7px; 
    margin-top: 0;
    margin-bottom: 0;
}

/* .category-container {
    margin-top: 30px;   
}

.category-container:first-child {
    margin-top: 0;     
} */
.product {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}


.product-list {
    overflow-x: hidden; 
}

.product-list p{
    margin: 20px;
}



/* .PRODUCT_EDITOR {
    background-color: var(--white);
    border: 0.5px solid var(--secondaryColor);
    width: fit-content;
}

.BAKED {
    background-color: var(--ochreLight);  
    width: 100%;
    margin-right: 1px;
    margin-left: 1px;
    border-left: 0.5px solid var(--black);
    border-right: 0.5px solid var(--black);
}

.UNSOLD {
    background-color: var(--white); 
    width: 100%; 
    margin-right: 1px;
    margin-left: 1px;
    border-left: 0.5px solid var(--black);
    border-right: 0.5px solid var(--black);
}

.WASTAGE {
    background-color: var(--lightGrey); 
    width: 100%;   
    margin-right: 1px;
    margin-left: 1px;
    border-left: 0.5px solid var(--black);
    border-right: 0.5px solid var(--black);
} */

.qty_editor {
    margin-right: 20px;
    display: flex;
    flex-direction: row;
    align-items:center;
}

.qty_editor input {
    text-align: center; 
}

.qty_editor input::placeholder{
    color: var(--lightGreen);
    text-align: right;
    opacity: 0.5;
}

.qty_input {
    font-size: 1.2rem;
    padding: 5px;
    margin-right: 3px;
    margin-left: 3px;
}

/* PRODUCT EDITOR */
.pe-product-editor{
    /* width: 100%; */
    overflow-y: auto;
    background-color: var(--lightGrey);
    padding-right: 20px;
}
.pe-product-editor-form {
        display: grid;
        grid-template-columns: 40% 1fr;
        gap: 10px;
}
.pe-label{
    text-align: right;
    margin: auto 0 auto auto;
}

.pe-input {
    padding: 10px 0;
}

.pe-section-two {
    width: 100%;
    display: grid;
    grid-template-columns: 150px 1fr;
    gap: 10px;    
}

.pe-section-two-revert {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 40%;
    gap: 10px;     
}

.pe-section-two-revert-100px {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr auto;
    gap: 10px;     
}

.pe-flex {
    background-color: beige;
    display:flex;
    flex-direction: row;
    justify-content:flex-end;
    margin:10px 20px 10px auto;
}

.pe-photo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.pe-buttons {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    /* margin: 10px 20px 10px auto; */
}

/* OVERLAY */
.product-page {
    display: grid;
    grid-template-columns: 420px 1fr;  

    height: 100%;
    width: 100%;
    overflow-y: hidden;
}

.pe-pl-container{
    background-color: var(--secondaryColor);
    height: 100%;
    overflow-y: hidden; 
}
.pe-pl-header {
    height: var(--upperPanelHeight);

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
.pe-pl-product-list {
    height: calc(100% - var(--upperPanelHeight));
    overflow-y: auto;
}

/* RECOMMENDATIONS */
.product-obj-layout {
    display: grid;
    align-items: center;
}

.product-obj-layout p {
    margin: 10px;
}

.po-40-60{
    grid-template-columns: 70px 1fr 1.5fr;
}

/* OPERATIONS */
.po-60-40{
    grid-template-columns: 60% 40%;
}

/* PRODUCTS */
.po-1fr-40px {
    display: grid;
    grid-template-columns: 70px 1fr 50px;
    align-items: center;
}

.product-img {
    width: 70px;
    height: 70px;
    clip-path: circle(32px);
    cursor: pointer;
}

.product-obj {
    display: flex;
    margin: 10px auto;
}

.product-obj input {
    margin-left: 5px;
    margin-right: 5px;
    text-align: center;
}

.product-obj input::placeholder {
    text-align: right;
    margin-right: 5px;
    color: var(--lightGreen);
}

/* RECOMMENDATIONS */
.rs {
    width: 100%;
    height: 100%;
    overflow-y: hidden;
}
.rs-header {
    height: 80px;
    display: grid;
    grid-template-columns: 1fr 1.5fr 200px;
}
.rs-subtitle {
    display: grid;
    grid-template-columns: 1fr 1.5fr 200px;
}
.rs-header h2 { 
    padding: 0;
    margin: auto auto auto 1rem;
}

.rs-container {
    display: grid;
    grid-template-columns: 1fr 200px;

    height: calc(100% - 80px);
    overflow-y: hidden;
}

.rs-scroll {
    overflow-y: auto;
}

.rs-days-container {
    background-color: var(--lightGrey);
    width: 200px;
    overflow-y: auto;
}

.rs-day {
    background-color: none;
    border: 1px solid var(--black);
    border-radius: 0 10px 10px 0;
    height: 50px;
    width: 95%;
    margin-top: 2px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.rs-selected-day {
    /* background-color: var(--primaryColor); */
    background-color: var(--light-light-green);
    color: var(--white);
}

/* SELECT SESSION 6:00 */
.rs-session-tab-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

}
.rs-session-tab-container div {
    height: 100%;
}
.rs-session-tab {
    margin: 2px;
    border: 2px solid var(--white);
    border-radius: 10px 10px 0 0;
    color: var(--white);
    font-size: 1.5rem;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.rs-tab-selected {
    background-color: var(--white);
    color: var(--black);
}
.rs-avg {
    font-size: 1.2rem;
}
